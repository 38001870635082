enum API {
	CANDIDATES = '/api/admission/candidates/export/xlsx',
	PAYMENTS = '/api/admission/payments/export/xlsx',
	ADMISSION_EXAMS = '/api/admission/exams/export/xlsx',
	ADIMSSION_FACULTIES = '/api/admission/faculties/export/xlsx',
	DISTRIBUTION = '/api/admission/distribution/export/xlsx',
	ADMISSION_MARKS = '/api/admission/marks/export/xlsx',
	APPEALS = '/api/admission/appeals/export/xlsx',
	APPEALS_DISTRIBUTION = '/api/admission/appealsDistribution/export/xlsx',
	USERS = '/api/settings/users/export/xlsx',
	SUMMARY = '/api/admission/summary/export/xlsx',
	LECTURERS = '/api/lecturers/main/export/xlsx',
	LECTURERS_SALARIES = '/api/lecturers/salaries/export/xlsx',
	STUDENTS = '/api/students/main/export/xlsx',
	STUDENT_PAYMENTS = '/api/students/payments/export/xlsx',
	STUDENT_APPLICATION = '/api/students/applications/export/xlsx',
	STUDENT_DISCOUNTS = '/api/students/discounts/export/xlsx',
	STUDENT_SURVEY = '/api/students/survey/export/xlsx',
	CURRICULUM_FACULTIES = '/api/curriculum/faculties/export/xlsx',
	CURRICULUM_FLOWS = '/api/curriculum/flows/export/xlsx',
	CURRICULUM_GROUPS = '/api/curriculum/groups/export/xlsx',
	CURRICULUM_LESSONS_WEEKS = '/api/curriculum/lessonsList/export/xlsx',
	CURRICULUM_LESSONS = '/api/curriculum/lessonsList/',
	CURRICULUM = '/api/curriculum/main/export/xlsx',
	CURRICULUM_BY_GROUP = '/api/curriculum/lessonGroups/export/xlsx/',
	SCHEDULE = '/api/curriculum/schedule/export/xlsx',
	EXAMS_ARCHIVED_MARKS_PDF = '/api/exams/archivedMarks/export/pdf',
	EXAMS_ARCHIVED_MARKS = '/api/exams/archivedMarks/export/xlsx',
	EXAMS = '/api/exams/main/export/xlsx',
	EXAMS_CONSULTATION = '/api/exams/consultation/export/xlsx',
	EXAMS_SUMMARY = '/api/exams/summary/export/xlsx',
	EXAMS_SUMMARY_ACADEMIC_YEAR = '/api/exams/summaryAcademicYear/export/xlsx',
	MARKS = '/api/exams/marks/export/xlsx',
	EXAMS_DISTRIBUTION = '/api/exams/distribution/export/xlsx',
	EXAMS_CONTROLLERS = '/api/exams/main/',
	COMMANDS = '/api/commands/export/xlsx',
	ATTENDANCE_MAIN = '/api/attendance/main/',
	ATTENDANCE_PRESENCE = '/api/attendance/presence/export/xlsx',
	ATTENDANCE_ABSENCE = '/api/attendance/absence/export/xlsx',
	ATTENDANCE_CLASSES = '/api/attendance/completedClasses/export/xlsx'
}

export default API;
