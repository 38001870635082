import {Button, Layout, Menu, MenuProps} from 'antd';
import Logo from 'assets/logo.svg';
import {MenuItem, SettingsContext} from 'context/Settings.context';
import {useLocalStorage} from 'hooks';
import {
	Archive,
	Bank,
	BookOpen,
	CalendarCheck,
	ChartBar,
	File,
	GraduationCap,
	House,
	List,
	Note,
	Notepad,
	Users,
	UsersThree
} from 'phosphor-react';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './Sider.less';
import {SYSTEM_TYPE_ID} from 'App';

const {Sider} = Layout;

const NOTIFICATION_PAGE_ID = 8;
const STATISTICS_PAGE_ID = 7;
const GUIDES_PAGE_ID = 63;
const SETTINGS_PAGE_ID = 9;
const PROFILE_PAGE_ID = 43;
const ADMISSION_NOTIFICATIONS_PAGE_ID = 18;
const SUBJECTS_PAGE_ID = 26;
export const ASSIGNMENT_PAGE_ID = 69;
export const CHAT_PAGE_ID = 70;

const ARCHIVE_PAGE_ID = 57;

const icons = [
	{
		id: 1,
		icon: <House size={24} weight="fill" />
	},
	{
		id: 2,
		icon: <Bank size={24} weight="fill" />
	},
	{
		id: 4,
		icon: <UsersThree size={24} weight="fill" />
	},
	{
		id: 3,
		icon: <GraduationCap size={24} weight="fill" />
	},
	{
		id: 5,
		icon: <Users size={24} weight="fill" />
	},
	{
		id: 32,
		icon: <BookOpen size={24} weight="fill" />
	},
	{
		id: 6,
		icon: <Note size={24} weight="fill" />
	},
	{
		id: 48,
		icon: <CalendarCheck size={24} weight="fill" />
	},
	{
		id: 59,
		icon: <File size={24} weight="fill" />
	},
	{
		id: 64,
		icon: <Archive size={24} weight="fill" />
	},
	{
		id: 67,
		icon: <ChartBar size={24} weight="fill" />
	},
	{
		id: 69,
		icon: <Notepad size={24} weight="fill" />
	}
];

const SiderMenu = () => {
	const [collapsed, setCollapsed] = useLocalStorage('sider_collapsed', false);
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const navigate = useNavigate();
	const {state: settingsState} = useContext(SettingsContext);
	const choosenPage = location.pathname;

	const {filesConfigs} = settingsState;

	const logoRef = useRef<HTMLImageElement>(null);

	const getMenuItems = (routes: MenuItem[], defPath = '') => {
		const menu = routes
			.filter(
				el =>
					el.pageId !== ADMISSION_NOTIFICATIONS_PAGE_ID &&
					el.pageId !== SETTINGS_PAGE_ID &&
					el.pageId !== GUIDES_PAGE_ID &&
					el.pageId !== NOTIFICATION_PAGE_ID &&
					el.pageId !== STATISTICS_PAGE_ID &&
					el.pageId !== PROFILE_PAGE_ID &&
					el.pageId !== SUBJECTS_PAGE_ID &&
					el.pageId !== CHAT_PAGE_ID &&
					!(SYSTEM_TYPE_ID === 2 && el.pageId === ASSIGNMENT_PAGE_ID)
			)
			.map(item => {
				let path = defPath + item.url.slice(item.url.lastIndexOf('/'));
				if (item.children && item.children.length) {
					// const submenuPath = path + item.children[0].url.slice(item.children[0].url.lastIndexOf('/'));
					// getMenuItems(item.children, path, breadcrumbNameMap);
					return (
						<Menu.SubMenu
							key={'sub-' + path}
							className="menuItem"
							title={item.page}
							icon={icons.find(el => el.id === item.pageId)?.icon}>
							{getMenuItems(item.children, path)}
						</Menu.SubMenu>
					);
				}

				if (path === '/main') {
					path = '/';
				}

				return (
					<Menu.Item
						className="menuItem"
						key={path}
						title={item.page?.length > 19 ? item.page : ''}
						icon={icons.find(el => el.id === item.pageId)?.icon}
						onClick={() => {
							navigate(path);
						}}>
						{item.page}
					</Menu.Item>
				);
			});

		return menu;
	};
	const menu = useMemo(
		() => getMenuItems(settingsState.menuItems, ''),
		[location.pathname, settingsState.menuItems]
	);

	const onOpenChange: MenuProps['onOpenChange'] = (keys: string[]) => {
		const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
		if (keys.indexOf(latestOpenKey!) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	useEffect(() => {
		if (choosenPage === '/settings') {
			setCollapsed(() => true);
		}
	}, [choosenPage]);

	const logoSrc = useMemo(() => {
		let src = Logo;
		const file = filesConfigs.find(el => el.typeID === 1);
		if (file) {
			src = process.env.REACT_APP_API_URL + '/' + file.path;
		}
		return src;
	}, [filesConfigs]);

	return (
		<Sider trigger={null} collapsible collapsed={collapsed} className="sider" width={282}>
			<div
				className="logo-container"
				style={
					collapsed
						? {
								flexDirection: 'column-reverse',
								justifyContent: 'center',
								height: 100,
								rowGap: 15
						  }
						: {}
				}>
				<img
					src={logoSrc}
					alt="Logo"
					ref={logoRef}
					style={{maxWidth: collapsed ? 70 : undefined}}
					onError={e => {
						if (logoRef.current) logoRef.current.src = Logo;
					}}
					onClick={() => {
						navigate('/');
					}}
					height={collapsed ? 33 : 42}
				/>
				{/* </div> */}
				{/* <div className="collapse-container"> */}
				<Button
					onClick={() => setCollapsed(prev => !prev)}
					type="text"
					icon={<List size={24} weight="fill" />}
				/>
			</div>
			<Menu
				className="menu"
				mode="inline"
				defaultSelectedKeys={[choosenPage]}
				openKeys={openKeys}
				onOpenChange={onOpenChange}
				selectedKeys={[choosenPage]}>
				{menu}
			</Menu>
			{/* <Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={['1']}
				items={[
					{
						key: '1',
						icon: <UserOutlined />,
						label: 'nav 1'
					},
					{
						key: '2',
						icon: <VideoCameraOutlined />,
						label: 'nav 2'
					},
					{
						key: '3',
						icon: <UploadOutlined />,
						label: 'nav 3'
					}
				]}
			/> */}
		</Sider>
	);
};

export default SiderMenu;
